//import logo from './logo.svg';
import './App.css';
import one from './one.svg';
import two from './two.svg';
import three from './three.svg';
import four from './four.svg';
import five from './five.svg';
import six from './six.svg';
import seven from './seven.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{ position: "fixed", top: "0", left: "0"}} > <a href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/65432546055801318839430108781197934175142071224669288956641376946263261446154"> minimalistic abstractism </a> </div>
        <img src={one} className="App-logo" alt="one" />
        <p>
          one
        </p>
        <img src={two} className="App-logo" alt="two" />
        <p>
          two
        </p>
        <img src={three} className="App-logo" alt="three" />
        <p>
          three
        </p>
        <img src={four} className="App-logo" alt="four" />
        <p>
          four
        </p>
        <img src={five} className="App-logo" alt="five" />
        <p>
          five
        </p>
        <img src={six} className="App-logo" alt="six" />
        <p>
          six
        </p>
        <img src={seven} className="App-logo" alt="seven" />
        <p>
          seven
        </p>
      </header>
    </div>
  );
}

export default App;
